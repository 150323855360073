import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import classNames from 'classnames';

import {
    Colours,
    Sizes,
    Paragraph,
    PrimaryButton,
    PrimaryAnchor,
    AlternateButton,
    AlternateAnchor,
    IconMapLazy,
    ImageSizes,
} from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';
import VisualElement from './VisualElement';


const VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

const CTA_TYPES = {
    PRIMARY: 'primary',
    ALTERNATE: 'alternate',
};

const MOBILE_LAYOUTS = {
    WITH_SUBTITLE: 'with-subtitle',
    WITH_IMAGE: 'with-image',
};

const ALIGNMENT_OPTIONS = {
    LEFT: 'left',
    CENTER: 'center', // CSS align values - so wrong spelling is required
    RIGHT: 'right',
};

const HERO_STYLES = {
    DEFAULT: 'default',
    WIDE: 'wide',
};

function CallToActionBlock({
    className,

    /**
     * This component currently supports the original CallToActionBlock props &
     * the original HeroBanner props, to be deprecated and replaced with one
     * set of shared props in FIT-581.
     */
    isHero = false,
    wrapVisual = false,
    variant = VARIANTS.DARK,
    heroStyle = HERO_STYLES.DEFAULT,

    // Hero Banner props
    useAd = false,
    title,
    subtitle,
    imageUrl,
    imageAlt,
    ctaText,
    ctaHref,
    mobileLayout = MOBILE_LAYOUTS.WITH_SUBTITLE,
    alignment = ALIGNMENT_OPTIONS.LEFT,

    // CTA Block props
    heading,
    message,
    children,
    ctaType = CTA_TYPES.PRIMARY,
    buttonText,
    onClick,
    href,
    visualIsAd,
    visualIconKey,
    visualSrc,
    visualAlt,
    visualHref,
    visualTarget = 'currentTab',
    imageCollection,

    ...otherProps
}) {
    const Button = ctaType === CTA_TYPES.ALTERNATE ? AlternateButton : PrimaryButton;
    const Anchor = ctaType === CTA_TYPES.ALTERNATE ? AlternateAnchor : PrimaryAnchor;
    const isButton = !!onClick;
    const ButtonOrAnchor = isButton ? Button : Anchor;

    const hasVisual = useAd || visualIsAd || imageUrl || visualSrc || visualIconKey && visualIconKey !== 'none' || imageCollection;

    const enrichedImageCollection = imageCollection
        ? createImageCollection(imageCollection, isHero)
        : undefined;

    return (
        <Container
            variant={variant}
            heroStyle={heroStyle}
            hasVisual={hasVisual}
            wrapVisual={useAd || visualIsAd || wrapVisual}
            clipVisual={mobileLayout === MOBILE_LAYOUTS.WITH_IMAGE}
            mobileLayout={mobileLayout}
            hideSubtitleOnMobile={mobileLayout === MOBILE_LAYOUTS.WITH_IMAGE}
            alignmentWhenNoVisual={alignment}
            hasAd={useAd || visualIsAd}
            isHero={isHero}
            className={className}
            {...otherProps}
        >
            <div className="text-container">
                <Choose>
                    <When condition={isHero}>
                        <h1 className={classNames(
                            { 'showDot': getShowDot(title) },
                            'rh-title-4xl',
                            'rh-my-2',
                            'rh-mx-0',
                            variant === VARIANTS.DARK
                                ? 'rh-fg-blackberry'
                                : 'rh-fg-coconut',
                        )}
                        >
                            {title}
                        </h1>
                    </When>

                    <Otherwise>
                        <HeadingBlock
                            variant={VARIANTS.DARK}
                            {...heading}
                        />
                    </Otherwise>
                </Choose>

                <If condition={isHero && subtitle || !isHero && message}>
                    <Paragraph
                        variant={isHero ? variant : VARIANTS.DARK}
                        className="subtitle rh-my-1_25"
                        size={isHero ? 'large' : 'normal'}
                        message={isHero ? subtitle : message}
                    />
                </If>

                {children}

                <If condition={(ctaHref || href || onClick) && (ctaText || buttonText)}>
                    <ButtonOrAnchor
                        className="cta"
                        onClick={onClick}
                        href={isHero ? ctaHref : href}
                        message={isHero ? ctaText : buttonText}
                        data-name="callToActionBlock-cta"
                    />
                </If>
            </div>

            <If condition={hasVisual}>
                <div className="visual-container">
                    <VisualElement
                        isAd={useAd || visualIsAd}

                        iconKey={visualIconKey}
                        iconStroke={Colours.BLUEBERRY}
                        iconStrokeWidth="1"
                        iconOutlineWidth="1"

                        imageUrl={isHero ? imageUrl : visualSrc}
                        alt={isHero ? imageAlt : visualAlt}

                        href={visualHref}
                        target={visualTarget}
                        isLazy={true}

                        imageCollection={enrichedImageCollection}
                        width={enrichedImageCollection ? enrichedImageCollection.width : 400}
                        height={enrichedImageCollection ? enrichedImageCollection.height : 400}
                    />
                </div>
            </If>
        </Container>
    );
}

function getShowDot(string) {
    return (!string?.slice(-1).match(/[?;!:]/));
}

CallToActionBlock.propTypes = {
    className: PropTypes.string,

    isHero: PropTypes.bool,
    wrapVisual: PropTypes.bool,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    heroStyle: PropTypes.oneOf(Object.values(HERO_STYLES)),

    // Hero Banner props
    useAd: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    ctaText: PropTypes.string,
    ctaHref: PropTypes.string,
    mobileLayout: PropTypes.string,
    alignment: PropTypes.oneOf(Object.values(ALIGNMENT_OPTIONS)),

    // CTA Block props
    heading: HeadingPropTypes,
    message: PropTypes.string,
    children: PropTypes.any,
    ctaType: PropTypes.oneOf(Object.values(CTA_TYPES)),
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,

    imageCollection: ImageCollectionPropType,

    visualIsAd: PropTypes.bool,
    visualIconKey: PropTypes.oneOf(Object.keys(IconMapLazy)),
    visualSrc: PropTypes.string,
    visualAlt: PropTypes.string,
    visualHref: PropTypes.string,
    visualTarget: PropTypes.oneOf([
        'currentTab',
        'newTab',
    ]),
};

/* STYLE CONSTS */
const MOBILE_LAYOUT_SWITCH = '50rem';
const MAX_WIDTH_TEXT_CONTAINER = '50rem';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .text-container {
        margin-right: ${Sizes.SPACING.FIVE};
        flex-grow: 1;

        ${props => !props.hasVisual && `
            text-align: ${props.alignmentWhenNoVisual};
            margin: auto;
            max-width: ${MAX_WIDTH_TEXT_CONTAINER};
        `}

        > .cta {
            min-width: 17em;
            max-width: 25em;

            margin: ${Sizes.SPACING.THREE} 0 0 0;

            ${props => !props.hasVisual && `
                margin-left: ${props.alignmentWhenNoVisual === 'left' ? 0 : 'auto'};
                margin-right: ${props.alignmentWhenNoVisual === 'right' ? 0 : 'auto'};
            `}
        }
    }

    > .visual-container {
        flex-basis: 33.3%;
        flex-grow: 0;
        flex-shrink: 0;

        img {
            border-radius: 50%;
        }

        img,
        svg {
            display: block;
            width: ${props => props.isHero ? '25.375em' : '18.75em'};
            height: ${props => props.isHero ? '25.375em' : '18.75em'};
        }
    }

    @media (max-width: ${MOBILE_LAYOUT_SWITCH}) {
        flex-direction: ${props => props.clipVisual && !props.wrapVisual ? 'row' : 'column-reverse'};

        > .text-container {
            margin-left: 0;
            margin-right: 0;
            /* If there is short text content, the container doesn't occupy the space, so the alignment of the text is off */
            width: 100%;

            > .subtitle {
                display: ${props => props.hideSubtitleOnMobile && !props.wrapVisual ? 'none' : 'block'};
            }

            > .cta {
                margin: ${Sizes.SPACING.THREE} auto 0 auto;
            }
        }

        > .visual-container {
            display: ${props => props.clipVisual || props.wrapVisual ? 'block' : 'none'};
            margin-bottom: ${Sizes.SPACING.THREE};

            svg {
                width: 5em;
                height: 5em;
            }
        }

        ${props => props.hasAd &&`
            flex-direction: column;

            > .visual-container {
                margin-bottom: 0;
                margin-top: ${Sizes.SPACING.THREE};
            }
        `}
    }

    /* apply drop shadow to .subtitle for heroes using light variants only */
    ${props => props.isHero && props.heroStyle === HERO_STYLES.WIDE && props.variant === VARIANTS.LIGHT && `
        > .text-container {
            > .subtitle {
                text-shadow: 1px 2px 11px ${rgba(Colours.BLACKBERRY, .4)};
            }
        }
    `}
`;

function createImageCollection(imageCollection, isHero) {
    const sizes = imageCollection.sizes;

    return isHero
        ? {
            sizes: [
                {
                    url: sizes?.[ImageSizes.L]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH}) and (min-resolution: 192dpi)`,
                },
                {
                    url: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH})`,
                },
            ],
            fallback: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
            height: sizes?.[ImageSizes.S]?.height || sizes[ImageSizes.FULL].height,
            width: sizes?.[ImageSizes.S]?.width || sizes[ImageSizes.FULL].width,
            alt: imageCollection.alt || imageCollection.title,
            mime: imageCollection.mime,
        }
        : {
            sizes: [
                {
                    url: sizes?.[ImageSizes.M]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH}) and (min-resolution: 192dpi)`,
                },
                {
                    url: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
                    breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH})`,
                },
            ],
            fallback: sizes?.[ImageSizes.S]?.url || sizes[ImageSizes.FULL].url,
            width: sizes?.[ImageSizes.S]?.width || sizes[ImageSizes.FULL].width,
            height: sizes?.[ImageSizes.S]?.height || sizes[ImageSizes.FULL].height,
            alt: imageCollection.alt || imageCollection.title,
            mime: imageCollection.mime,
        };
}

CallToActionBlock.blockKey = 'rh/call-to-action-block';
CallToActionBlock.hasHeading = true;
CallToActionBlock.requiresLayoutRow = true;

export default CallToActionBlock;
