import { Products } from '@ratehub/base-ui';
import { LOANFINDER_API_RESPONSE_PRE_RELEASE } from '@ratehub/loans-loanfinder/mocks';
import { getLoanShape } from '@ratehub/loans-loanfinder';

import getApplyHrefForBanking from './getApplyHrefForBanking';


/**
 * Re-fetch the loans referenced within the `requested` object within the slots array and use that API response
 * to augment the information captured in the static CMS-based content found in `requested`.
 *
 * @param {Array.<{requested: Object[], fallback: Object, provinces: String[]}>} slots
 * @param {Object} options
 * @return {Promise<void>} - we return a Promise; slots will be mutated
 */
// eslint-disable-next-line no-unused-vars          -- remove this line when we use options in the fetch
async function enrichLoanProducts(slots, options) {
    // const response = await fetchLoans[?]({
    //     products: slots.map(({ requested, fallback }) => ({
    //         requested: requested.map(product => ({
    //             id: product.id,
    //         })),
    //         fallback: {
    //             type: fallback?.type,
    //         },
    //     })),
    //     ...options,
    // });
    // @TODO replace the below fake API result with a real one from the missing fetch above
    const response = {
        data: {
            products: LOANFINDER_API_RESPONSE_PRE_RELEASE.data.loans.map(getLoanShape) || [],
        },
    };

    // do NOT support the legacy return shape
    const products = response.data?.products;


    slots.forEach((slot, index) => {
        let matchedLoan;

        for (let loanIndex = 0; loanIndex < slot.requested.length; ++loanIndex) {
            const loan = slot.requested[loanIndex];
            matchedLoan = products.find(
                product => product?.id === loan.id
            );

            // a matched loan is not guaranteed [?]
            if (matchedLoan) {
                enrichOneLoanProduct(loan, matchedLoan, slot);
                // We only need to enrich the first product we find
                break;
            }
        }

        // If no matchedLoan is found, use fallback
        if (!matchedLoan) {
            const fallbackLoan = products[index];

            if (fallbackLoan) {
                enrichOneLoanProduct(slot.fallback, fallbackLoan, slot);
            }
        }
    });
}

function enrichOneLoanProduct(cmsEntry, apiEntry, slot) {
    if (!cmsEntry || !apiEntry) {
        throw new Error(
            `[enrichOneLoanProduct] Both enrichment arguments for loans are required. cmsEntry: ${JSON.stringify(cmsEntry, null, 4)}; apiEntry: ${JSON.stringify(apiEntry, null, 4)}`,
        );
    }

    Object.assign(slot, {
        id: apiEntry.id,
        isSponsored: cmsEntry.isSponsored || apiEntry.isSponsored,
        applyHref: getApplyHrefForBanking(cmsEntry, apiEntry),
        applyText: cmsEntry.applyText,
        description: cmsEntry.description || apiEntry.badgeDescription,

        title: cmsEntry.titleOverride || apiEntry?.provider.name,
        // isMonetized should always be passed as true from BE
        isMonetized: apiEntry.isMonetized,

        // provider information sourced from apiEntry
        providerName: apiEntry?.provider.name,
        imageSrc: apiEntry?.provider.logo,
        imageAlt: apiEntry?.provider.name || '',
        providerSlug: apiEntry?.provider.slug || '',

        // we're not doing rate at the moment, but if we do, we may want a range of rates
        // that would require FeatureProducts to recognize that situation
        // rate: apiEntry.aprRange.apr,

        productType: Products.LOANS,
    });
}



export default enrichLoanProducts;
