import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
    LegacyCityPropType,
    BusinessUnits,
    ProductCardBrief,
    getLanguageFromLocale,
} from '@ratehub/base-ui';
import {
    CreditCardCTA,
    CreditCardSynopsisShape,
    useApplyForCreditCardCallback,
} from '@ratehub/cc-common';
import { RATEHUB_BROKERAGE_IDS } from '@ratehub/mtg-common';

import { Products } from '../definitions/Products';


function FeaturedProduct({
    className,

    isSponsored,
    hasPromo,

    title,
    applyText,
    applyHref,
    applyHrefRedirect,
    imageSrc,
    imageAlt,
    rate,
    description,
    productTypeOverride,

    productType,
    providerId,
    providerName,

    creditCardSynopsis,
    analyticsObject,

    mortgageCallback,

    hasMinWidth = true,
    hasMaxWidth = true,
    isCompact = false,

    as = 'li',

    city,
    hideCTAIfNotMonetized = false,
    ...otherProps
}) {
    const intl = useIntl();
    const languageCode = getLanguageFromLocale(intl.locale);

    const applyForCreditCardCallback = useApplyForCreditCardCallback();

    const isARatehubCompany = productType === Products.MORTGAGES
        && Object.values(RATEHUB_BROKERAGE_IDS).includes(providerId);

    async function handleCCCallback() {
        applyForCreditCardCallback({
            cardInfo: creditCardSynopsis,
            applyURLOverride: applyHrefRedirect ?? applyHref,
            languageCode,
        });
    }

    async function handleMortgageClick() {
        mortgageCallback?.({ rate, city });
    }

    function getComponentWithProps() {
        const componentClassName = hasPromo
            ? 'has-promo'
            : '';

        switch (productType) {
            case Products.CREDIT_CARDS: {
                return hideCTAIfNotMonetized && !creditCardSynopsis.isMonetized
                    ? [
                        'div',
                        {},
                    ]
                    : [
                        CreditCardCTA,
                        {
                            cardTypes: creditCardSynopsis.cardTypes,
                            giftCardOffer: creditCardSynopsis.giftCardOffer,
                            cardId: creditCardSynopsis.id,

                            onClick: handleCCCallback,
                            subtextOptions: { reducePaddingAbove: true },
                            // short-term? hack to squeeze in four featured products, including a CTA "check eligibility 🎁"
                            // buttonSize: 'large',     // 1.125 rem (original value, but too wide with 4-up and long CTA)
                            // buttonSize: 'medium',    // 1 rem
                            buttonSize: 'small',        // 1 rem, but less padding
                            className: componentClassName,
                            productType: productTypeOverride,
                            buttonText: applyText?.trim().length > 0
                                ? applyText
                                : undefined,
                        },
                    ];
            }

            case Products.MORTGAGES:
                return [
                    'div',
                    {
                        onClick: handleMortgageClick,
                        className: componentClassName,
                    },
                ];

            default:
                return [
                    'a',
                    {
                        href: applyHrefRedirect || applyHref,
                        target: '_blank',
                        rel: 'noopener',
                        className: componentClassName,
                    },
                ];
        }
    }


    return (
        <ProductCardBrief
            {...otherProps}
            analyticsObject={analyticsObject}
            className={className}
            CTAComponentWithProps={getComponentWithProps()}
            isSponsored={isSponsored}
            rate={rate}
            imageSrc={imageSrc}
            imageAlt={imageAlt}
            isARatehubCompany={isARatehubCompany}
            description={description}
            productType={productType}
            applyText={applyText}
            title={title}
            providerName={providerName}
            hasPromo={hasPromo}
            hasMinWidth={hasMinWidth}
            hasMaxWidth={hasMaxWidth}
            isCompact={isCompact}
            as={as}
        />
    );
}

FeaturedProduct.propTypes = {
    className: PropTypes.string,

    isSponsored: PropTypes.bool.isRequired,
    hasPromo: PropTypes.bool.isRequired,

    title: PropTypes.string.isRequired,

    applyHref: PropTypes.string.isRequired,
    applyText: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,

    applyHrefRedirect: PropTypes.string,
    rate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ]),
    description: PropTypes.string,
    productTypeOverride: PropTypes.string,

    productType: PropTypes.oneOf(
        [ ...new Set(Object.values(Products).concat(Object.values(BusinessUnits))) ],
    ).isRequired,

    providerId: PropTypes.number,
    providerName: PropTypes.string,

    creditCardSynopsis: PropTypes.shape(CreditCardSynopsisShape),
    analyticsObject: PropTypes.object.isRequired,

    mortgageCallback: PropTypes.func,

    hasMinWidth: PropTypes.bool,
    hasMaxWidth: PropTypes.bool,
    isCompact: PropTypes.bool,

    as: PropTypes.string,
    city: LegacyCityPropType.isRequired,
    hideCTAIfNotMonetized: PropTypes.bool,      // this prop is only relevant to credit cards at the moment
};

export default FeaturedProduct;
