import { lazy } from 'react';

import noticeError from './noticeError';
import timeoutAsPromise from './timeoutAsPromise';


// Using a small interval since this is evaluated client-side
const MAX_ATTEMPTS = 3;
const SLEEP_INTERVAL = 100; 

/**
 * @private
 * Retry importing a lazy-loaded file. 
 * 
 * This solution is from https://blog.logto.io/react-safe-lazy#handle-the-retries
 *  to remedy our ChunkLoadError
 *
 * @param {function} dynamicImport dynamic import passed to React.lazy
 * @returns {function} a React.lazy function
 */
function lazyWithRetry(dynamicImport) {
    return lazy(() => retryDynamicImport(dynamicImport));
}

async function retryDynamicImport(dynamicImport, attempts = 0) {
    // Sleep using a linear back-off.
    if (attempts > 0) {
        const timeoutDuration = getSleepMSForAttempt(attempts);
        await timeoutAsPromise(timeoutDuration);
    }

    // NOTE: increasing attempt here so we can start at attempt 1
    //  and better calculate when we've reached MAX_ATTEMPTS
    attempts++;

    try {
        return await dynamicImport();
    } catch (error) {
        // Retry our import if we're under the max attempts
        if (attempts <= MAX_ATTEMPTS) {
            return retryDynamicImport(dynamicImport, attempts);
        }

        noticeError(error, {
            message: '[lazyWithRetry] Max retries met for import',
        });

        throw error;
    }
}

function getSleepMSForAttempt(attempt) {
    return attempt * SLEEP_INTERVAL;
}

export default lazyWithRetry;
// Export for testing
export {
    getSleepMSForAttempt,
    MAX_ATTEMPTS,
};