import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components';

import { IconChooser, Colours } from '@ratehub/base-ui';

import getValueWithoutUnit from '../functions/getValueWithoutUnit';
import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import AdBigbox from './AdBigbox';


const WEBP_MIME = 'image/webp';
const WEBP_EXTENSION = '.webp';

function VisualElement(props) {
    const {
        isAd = false,

        iconKey,
        iconComponent,
        iconStroke = Colours.BLACKBERRY,
        iconStrokeWidth = '2',
        iconOutlineWidth = '2',

        imageUrl,
        alt = '',
        imageCollection,
        isLazy = false,
        restrictImageWidth = false,

        borderRadius,
        borderWidth,
        borderColour,
        customWidth,
        customHeight,

        dataName,

        ...otherProps
    } = props;

    const Icon = iconComponent;
    const Image = isLazy ? StyledLazyLoadImage : StyledImg;

    return (
        <Choose>
            <When condition={isAd}>
                <AdBigbox
                    alignment={AdBigbox.ALIGNMENTS.LEFT}
                    {...otherProps}
                />
            </When>

            {/* NOTE: We currently do not support `fill` as an icon prop in this
            component, despite most (if not all) icons accepting it. We would
            like to add this but need to make sure our icons are using it
            properly first. Many icons apply fill poorly, which results in
            broken-looking icons.
            See https://ratehub.atlassian.net/browse/FIT-1599 */}
            <When condition={iconKey && iconKey !== 'none'}>
                <IconChooser
                    size="FILL"
                    iconKey={iconKey}
                    stroke={iconStroke}
                    strokeWidth={iconStrokeWidth}
                    outlineWidth={iconOutlineWidth}
                    {...otherProps}
                />
            </When>
            <When condition={iconComponent}>
                <Icon
                    stroke={iconStroke}
                    strokeWidth={iconStrokeWidth}
                    outlineWidth={iconOutlineWidth}
                    width={customWidth && getValueWithoutUnit(customWidth)}
                    height={customHeight && getValueWithoutUnit(customHeight)}
                    {...otherProps}
                />
            </When>
            <When condition={imageCollection}>
                <picture>
                    <If condition={imageCollection.mime === WEBP_MIME}>
                        <For
                            each="image"
                            of={imageCollection.sizes}
                        >
                            <source
                                key={image.url + image.breakpoint}
                                srcSet={image.url.substring(0, image.url.length - WEBP_EXTENSION.length)}
                                media={image.breakpoint}
                                /**
                                 * Commenting this out here because the behaviour is different for Chrome/Firefox
                                 * Chrome uses the width/height in the source tag, but Firefox only uses the ones on the image tag
                                 * Maybe one day we will want to use this, but it also needs to work with the customWidth/customHeight option
                                 */
                                // width={image.width}
                                // height={image.height}
                                data-name={dataName}
                            />
                        </For>
                    </If>

                    <For
                        each="image"
                        of={imageCollection.sizes}
                    >
                        <source
                            key={image.url + image.breakpoint}
                            srcSet={image.url}
                            media={image.breakpoint}
                            type={imageCollection.mime}
                            // See comment above
                            // width={image.width}
                            // height={image.height}
                            data-name={dataName}
                        />
                    </For>

                    <Image
                        src={imageCollection.fallback}
                        alt={alt || imageCollection.alt || imageCollection.title || ''}
                        width={customWidth ? getValueWithoutUnit(customWidth) : imageCollection.width}
                        height={customHeight ? getValueWithoutUnit(customHeight) : imageCollection.height}
                        $restrictImageWidth={restrictImageWidth}
                        $borderRadius={borderRadius}
                        $borderWidth={borderWidth}
                        $borderColour={borderColour}
                        data-name={dataName}
                        {...otherProps}
                    />
                </picture>
            </When>
            {/* imageCollection should take priority */}
            <When condition={imageUrl && !imageCollection}>
                <Image
                    src={imageUrl}
                    alt={alt}

                    $restrictImageWidth={restrictImageWidth}
                    $borderRadius={borderRadius}
                    $borderWidth={borderWidth}
                    $borderColour={borderColour}
                    width={customWidth ? getValueWithoutUnit(customWidth) : undefined}
                    height={customHeight ? getValueWithoutUnit(customHeight) : undefined}
                    data-name={dataName}
                    {...otherProps}
                />
            </When>
        </Choose>
    );
}

VisualElement.propTypes = {
    isAd: PropTypes.bool,
    targeting: PropTypes.object,

    iconKey: PropTypes.string,
    iconComponent: PropTypes.func,
    iconStroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    iconStrokeWidth: PropTypes.string,
    iconOutlineWidth: PropTypes.string,

    imageUrl: PropTypes.string,
    alt: PropTypes.string,
    imageCollection: ImageCollectionPropType,
    isLazy: PropTypes.bool,
    restrictImageWidth: PropTypes.bool,

    borderRadius: PropTypes.string,
    borderWidth: PropTypes.string,
    borderColour: PropTypes.oneOf(Object.values(Colours)),
    customWidth: PropTypes.string,
    customHeight: PropTypes.string,

    dataName: PropTypes.string,
};

const IMAGE_STYLES = css`
    border-radius: ${props => props.$borderRadius};
    border: ${props => computeBorderStyle(props.$borderWidth, props.$borderColour)};

    ${props => props.$restrictImageWidth && `
        max-width: 100%;
        height: auto;
    `}
`;

const StyledLazyLoadImage = styled(LazyLoadImage)`
    ${IMAGE_STYLES};
`;

const StyledImg = styled.img`
    ${IMAGE_STYLES};
`;

function computeBorderStyle(size, colour) {
    return size && colour
        ? `${size} solid ${colour}`
        : 'none';
}

export default VisualElement;
