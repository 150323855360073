import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import classNames from 'classnames';

import Colours from '../definitions/Colours';
import Config from '../definitions/Config';
import ZIndex from '../definitions/ZIndex';
import noticeError from '../functions/noticeError';
import useIsMounted from '../hooks/useIsMounted';
import isElementVisible from '../functions/isElementVisible';


// Currently there's a bug with Storybook when trying to import these directly from SASS, so defining here for now.
const POSITION_MAP = {
    'absolute': 'rh-position-absolute',
    'fixed': 'rh-position-fixed',
};

// Currently there's a bug with Storybook when trying to import these directly from SASS, so defining here for now.
const ZINDEX_MAP = {
    1000: 'rh-zindex-elements',
    2000: 'rh-zindex-drop-downs',
    3000: 'rh-zindex-secondary-navigation',
    4000: 'rh-zindex-header-footer',
    5000: 'rh-zindex-primary-navigation',
    6000: 'rh-zindex-full-screen',
    7000: 'rh-zindex-special-cases',
    8000: 'rh-zindex-modals',
    9000: 'rh-zindex-notifications',
};

function CircleSpinner({
    zIndex = ZIndex.FULL_SCREEN,
    position = 'absolute',
    className,
    onTimeout,
    timeoutDuration = Config.CIRCLE_SPINNER_TIMEOUT_MILLISECONDS,
    ...otherProps
}) {
    const ref = useRef(null);
    const getIsMounted = useIsMounted();

    // run onTimeout function after timeout
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!getIsMounted() || !isElementVisible(ref.current)) {
                return;
            }

            if (onTimeout) {
                onTimeout();
            } else {
                noticeError('<CircleSpinner /> timed out, missing onTimeout function'); 
            }
        }, timeoutDuration);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const positionClass = POSITION_MAP[position];
    const zIndexClass = ZINDEX_MAP[zIndex];

    return (
        <>
            <CircleSpinner.Background
                className={classNames(
                    'rh-bg-coconut-dark',
                    positionClass,
                    zIndexClass,
                    className,
                )}
                data-name="spinner"
                {...otherProps}
            />

            <CircleSpinner.Circle
                ref={ref}
                className={classNames(
                    'rh-bg-blueberry rh-icon-xl rh-border-radius-50p',
                    positionClass,
                    zIndexClass,
                    className,
                )}
            />
        </>
    );
}

CircleSpinner.propTypes = {
    zIndex: PropTypes.oneOf(
        Object.values(ZIndex),
    ),
    position: PropTypes.oneOf([ 'fixed', 'absolute' ]),
    className: PropTypes.string,

    onTimeout: PropTypes.func,
    timeoutDuration: PropTypes.number,
};

CircleSpinner.Background = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.95;
`;

const growAndShrinkAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(0.25);
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) scale(0.25);
    }
`;

const colourChangeAnimation = keyframes`
    0% {
        background-color: ${Colours.BLUEBERRY};
    }

    20% {
        background-color: ${Colours.LIME};
    }

    40% {
        background-color: ${Colours.YUZU};
    }

    60% {
        background-color: ${Colours.TANGERINE};
    }

    80% {
        background-color: ${Colours.WATERMELON};
    }

    100% {
        background-color: ${Colours.BLUEBERRY};
    }
`;

CircleSpinner.Circle = styled.div`
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) scale(0.25);
    transform-origin: center center;

    animation:
        ${colourChangeAnimation} 10s infinite,
        ${growAndShrinkAnimation} 2s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
`;

export default CircleSpinner;
