// roughly based on LoanFinder results as of Dec-12, which are not quite correct

/* eslint-disable */
export default {
    "data": {
        "loans": [
            {
                "ids": {
                    "primary": "fig-financial-unsecured-loan",
                    "cms": 213822
                },
                "name": "Fig Financial",
                "slug": "fig-financial-unsecured-loan",
                "description": {
                    "summary": [
                        "Fig’s Fabulous Fun Loan"
                    ],
                    "disclaimer": "You will only receive a loan offer and be approved for a loan if you meet Fig’s current credit standards which include the following conditions: you do not currently have an active Fig loan and you have not been declined by Fig within the last 30 days. Further, any approval received is conditional to Fig’s standard review which may include, a verification of (i) your identity, (ii) information provided as part of your application and (iii) your ability to pay. Fig loans range from $2,000 to $35,000 with terms from 24 to 60 months. Annual Percentage Rates (APRs) range from 8.99% to 24.99%. The APR is the same as the interest rate. Your loan amount and your APR will depend on the assessment of your credit profile. Offer excludes residents of Northwest Territories, Nunavut and Yukon."
                },
                "category": "personal",
                "apr": {
                    "min": 5.5,
                    "max": 6.5,
                    "value": null
                },
                "loan_amount": {
                    "min": 7418,
                    "max": 14913,
                    "value": null
                },
                "term": {
                    "min": 6,
                    "max": 16,
                    "value": null
                },
                "eligibility": {
                    "credit_score": {
                        "private": "3-good"
                    }
                },
                "hidden": false,
                "monetized": true,
                "secured": true,
                "modified": "2024-12-10T14:38:07-05:00",
                "prohibited_languages": [
                    "fr"
                ],
                "prohibited_provinces": [],
                "provider": {
                    "ids": {
                        "primary": "fig-financial",
                        "cms": 213775,
                        "salesforce": null,
                        "legacy": null
                    },
                    "name": "Fig Financial",
                    "slug": "fig",
                    "logo": "https://cms.ratehub.ca/wp-content/uploads/2024/11/ENFig-logo-reverse.png",
                    "bu_details": {
                        "active": true,
                        "over_budget": false,
                        "languages": [
                            "en"
                        ],
                        "provinces": [
                            "ON"
                        ],
                        "site": null,
                        "contact": {
                            "emails": [],
                            "phone": null
                        }
                    },
                    "billing_profile": null
                },
                "urls": {
                    "apply": "https://framed.wtf",
                    "apply_redirect": "https://www.ratehub.dev/loans/apply/fig-financial-unsecured-loan?language=en",
                    "image": "https://cms.ratehub.ca/wp-content/uploads/2024/11/ENFig-logo-reverse.png"
                },
                "approval_likelihood": 4,
            },
            {
                "ids": {
                    "primary": "spring-financial-unsecured-loan",
                    "cms": 210331
                },
                "name": "Spring Financial",
                "slug": "spring-financial-unsecured-loan",
                "description": {
                    "summary": [
                        "test summary"
                    ]
                },
                "category": "personal",
                "apr": {
                    "min": null,
                    "max": null,
                    "value": 5
                },
                "loan_amount": {
                    "min": null,
                    "max": null,
                    "value": 5000
                },
                "term": {
                    "min": null,
                    "max": null,
                    "value": 5
                },
                "eligibility": {
                    "credit_score": {
                        "private": "5-excellent"
                    }
                },
                "hidden": false,
                "monetized": true,
                "secured": false,
                "modified": "2024-12-10T13:58:52-05:00",
                "prohibited_languages": [],
                "prohibited_provinces": [
                    "NU"
                ],
                "provider": {
                    "ids": {
                        "primary": "spring-financial",
                        "cms": 55133,
                        "salesforce": 9000,
                        "legacy": null
                    },
                    "name": "Spring Financial",
                    "slug": "spring-financial",
                    "logo": "https://cms.ratehub.ca/wp-content/uploads/2024/11/Spring.png",
                    "bu_details": {
                        "active": true,
                        "over_budget": false,
                        "languages": [
                            "en",
                            "fr"
                        ],
                        "provinces": [
                            "ON"
                        ],
                        "site": null,
                        "contact": {
                            "emails": [],
                            "phone": null
                        }
                    },
                    "billing_profile": null
                },
                "urls": {
                    "apply": "https://apply.fig.ca/#/application/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3N1ZWRfYXQiOjE3MzA3NDg3ODAsInBhcnRuZXJfaWQiOiIwMDFOMzAwMDAwUEpIV2MifQ.8mJXkh6jDfrPQpMQJ6DDbYmkBlshohJ_HMQxiKqfwbY/?partner_session_id=&campaign_id=test",
                    "apply_redirect": "https://www.ratehub.dev/loans/apply/test?language=en",
                    "image": "https://cms.ratehub.ca/wp-content/uploads/2024/11/Spring.png"
                },
                "approval_likelihood": 0
            }
        ],
        "fetchedCategories": [
            "car",
            "personal",
            "business"
        ]
    }
}
/* eslint-enable */
