// NOTE: This file is untranspiled because it needs to be usable by Node directly, and within next.config.js (which uses require)
/* eslint-disable import/no-commonjs */

// !!
// IMPORTANT FOR DEVS: Any code used by next.js shouldn't use this file directly; it should only use config.js instead!
// !!


/**
 * Get the environmental configuration we are running with
 * @param {boolean} isServerContext TRUE = return the server configuration; FALSE = return the client configuration
 * @returns {object} Environment configuration
 */
function getEnvironment(isServerContext = false) {
    // IMPORTANT: API_GATEWAY_URL should only be used by a fetch call; it SHOULD NOT appear in a URL out to the client,
    //            otherwise there may be a client/server mismatch caused by React hydration.
    // WHY DO THIS?: We want API keep all API calls inside the cluster during exports to reduce latency and avoid any rate limiting issues.
    //            To accomplish this, we can use the internal API gateway URL, instead of going out to the internet and back in.
    // ASSUMPTION: Cloudflare doesn't do any adjustments to any gateway URLs calls; it just proxies any requests directly to it.
    const API_GATEWAY_URL = (isServerContext)
        // We need to fall back to API_GATEWAY_URL for cases outside our cluster and so that local development works out of the box.
        ? getStringFromEnv(process.env.API_GATEWAY_URL_SERVER_OVERRIDE, getStringFromEnv(process.env.API_GATEWAY_URL, 'https://api.ratehub.dev'))
        : getStringFromEnv(process.env.API_GATEWAY_URL, 'https://api.ratehub.dev');

    // Server Base URLs.
    const RATEHUB_BASE_URL = getStringFromEnv(process.env.RATEHUB_BASE_URL, 'https://www.ratehub.dev');
    const API_LEGACY_URL = getStringFromEnv(process.env.API_LEGACY_URL, RATEHUB_BASE_URL + '/api');
    const CW_GATEWAY_URL = getStringFromEnv(process.env.CW_GATEWAY_URL, 'https://kennedy-api.canwise.com');
    const INSURANCE_API_URL = getStringFromEnv(process.env.INSURANCE_API_URL, API_GATEWAY_URL + '/insurance');
    const PROVIDER_SERVICE_URL = getStringFromEnv(process.env.PROVIDER_SERVICE_URL, API_GATEWAY_URL + '/provider-service');
    const RATEHUB_WIDGET_URL = getStringFromEnv(process.env.RATEHUB_WIDGET_URL, RATEHUB_BASE_URL);

    const CONTENT_API_URL = getStringFromEnv(process.env.CONTENT_API_URL, 'https://cms.ratehub.dev/wp-json');
    const CONTENT_ASSET_URL = getStringFromEnv(process.env.CONTENT_ASSET_URL, 'https://cms.ratehub.dev/wp-content/uploads');

    return {
        /*
            --- INDEX ---
            Search the following terms to quickly find sections:

            --- URLs ---
            URLs: General
            URLs: Everyday Banking
            URLs: Insurance
            URLs: Mortgages

            --- Configs ---
            Configs: General
            Configs: Everyday Banking
            Configs: Insurance
            Configs: Mortgages

            --- Feature Flags ---
            Feature Flags: General
            Feature Flags: Everyday Banking
            Feature Flags: Insurance
            Feature Flags: Mortgages

            --- Experiments ---
        */

        // *********
        // URLS
        // **********

        // URLs: General (non-BU specific) ------------------------------------------------------------

        /** Base URL for the main Ratehub site */
        RATEHUB_BASE_URL,

        /** Base URL for the Ratehub BBM API */
        API_LEGACY_URL,

        /** Base URL for the Ratehub API gateway */
        API_GATEWAY_URL,

        /** Base URL for provider service */
        PROVIDER_SERVICE_URL,

        /** Base URL for the widget loader script */
        RATEHUB_WIDGET_URL,

        /** Full URL to the IP-to-Location API */
        IP_LOC_API_URL: getStringFromEnv(process.env.IP_LOC_API_URL, `${API_GATEWAY_URL}/ip-to-location`),

        /** Full URL to the CMS (WordPress) API which contains non-blog data */
        CONTENT_API_URL,

        /** Full URL to the CMS uploads folder which contains image assets */
        CONTENT_ASSET_URL,

        /** Pages APIs (WordPress) */
        PAGE_LIST_API_URL: getStringFromEnv(process.env.PAGE_LIST_API_URL, CONTENT_API_URL + '/ratehub/v1/pages'),
        PAGE_DETAILS_API_URL: getStringFromEnv(
            process.env.PAGE_DETAILS_API_URL,
            CONTENT_API_URL + '/ratehub/v1/page-details',
        ),

        /** Menus API (WordPress) */
        MENUS_API_URL: getStringFromEnv(process.env.MENUS_API_URL, CONTENT_API_URL + '/menus/v1/menus'),

        /** Full URL to the blog API */
        BLOG_LATEST_API_URL: getStringFromEnv(process.env.BLOG_LATEST_API_URL, CONTENT_API_URL + '/wp/v2/posts'),

        /** Full URL to the Blog Post List API (WordPress) */
        BLOG_LIST_API_URL: getStringFromEnv(process.env.BLOG_LIST_API_URL, CONTENT_API_URL + '/ratehub/v1/posts'),

        /** Full URL to the Blog Post Details API (WordPress) */
        BLOG_DETAILS_API_URL: getStringFromEnv(
            process.env.BLOG_DETAILS_API_URL,
            CONTENT_API_URL + '/ratehub/v1/post-details',
        ),

        /** Full URL to the Blog Tags API (WordPress) */
        TAGS_API_URL: getStringFromEnv(process.env.TAGS_API_URL, CONTENT_API_URL + '/ratehub/v1/tags'),

        /** Full URL to the Blog Categories API (WordPress) */
        CATEGORIES_API_URL: getStringFromEnv(process.env.CATEGORIES_API_URL, CONTENT_API_URL + '/wp/v2/categories'),

        /** Full URL the external authors API */
        BLOG_EXTERNAL_AUTHORS: getStringFromEnv(
            process.env.BLOG_EXTERNAL_AUTHORS,
            CONTENT_API_URL + '/wp/v2/external_authors',
        ),

        /** API URL to retrieve the set of users from WordPress */
        WORDPRESS_USERS_API_URL: getStringFromEnv(process.env.WORDPRESS_USERS_API_URL, CONTENT_API_URL + '/wp/v2/users'),

        /** RH Insurance custom posts */
        RATEHUB_INSURANCE_PAGES_URL: getStringFromEnv(
            process.env.RATEHUB_INSURANCE_PAGES_URL,
            CONTENT_API_URL + '/wp/v2/rh_insurance',
        ),

        TABLE_DETAILS_URL: getStringFromEnv(process.env.TABLE_DETAILS_URL, `${CONTENT_API_URL}/ratehub/v1/table-details`),

        /** Full URL to the Newsletter Signup API. */
        NEWSLETTER_API_URL: getStringFromEnv(process.env.NEWSLETTER_API_URL, `${API_GATEWAY_URL}/subscribe-newsletter`),

        /** Full URL to the Ratehub cities API. */
        CITIES_API_URL: getStringFromEnv(process.env.CITIES_API_URL, `${API_GATEWAY_URL}/cities-search`),

        /** Full URL to the Ratehub Charts API */
        HISTORICAL_DATA_API_URL: getStringFromEnv(process.env.HISTORICAL_DATA_API_URL, API_GATEWAY_URL + '/historical-data'),

        /** Full URL to the Ratehub experiments API */
        EXPERIMENTS_API_URL: getStringFromEnv(process.env.EXPERIMENTS_API_URL, `${CONTENT_API_URL}/wp/v2/experiments`),

        /** Full URL to the Ratehub injection experiments API */
        INJECTION_EXPERIMENTS_API_URL: getStringFromEnv(process.env.INJECTION_EXPERIMENTS_API_URL, `${CONTENT_API_URL}/wp/v2/injection_experiment`),

        /** Full URL to submit conversions to */
        CONVERSION_SUBMIT_URL: getStringFromEnv(process.env.CONVERSION_SUBMIT_URL, `${API_GATEWAY_URL}/new/conversion`),

        /**  */
        CONVERSION_FIND_URL: getStringFromEnv(process.env.CONVERSION_FIND_URL, `${API_GATEWAY_URL}/find-conversions`),

        /** Full URL to the TFSA Contribution Limits API */
        TFSA_CONTRIBUTION_LIMIT_URL:
            process.env.TFSA_CONTRIBUTION_LIMIT_URL || CONTENT_API_URL + '/ratehub/v1/tfsa_contributions',

        /** Full URL to the RRSP Contribution Limits API */
        RRSP_CONTRIBUTION_LIMIT_URL:
            process.env.RRSP_CONTRIBUTION_LIMIT_URL || CONTENT_API_URL + '/ratehub/v1/rrsp_contributions',

        /** Full URL to the CMS endpoint which returns Blueprints */
        RATEHUB_BLUEPRINTS_API_URL: getStringFromEnv(
            process.env.RATEHUB_BLUEPRINTS_API_URL, CONTENT_API_URL + '/wp/v2/blueprints',
        ),

        /** Full URL to the CMS endpoint which returns Siderail Settings */
        RATEHUB_SIDERAIL_SETTINGS_API_URL: getStringFromEnv(
            process.env.RATEHUB_SIDERAIL_SETTINGS_API_URL, CONTENT_API_URL + '/wp/v2/siderail_settings',
        ),

        /** profile-faas address */
        PROFILE_BFF_URL: getStringFromEnv(process.env.PROFILE_BFF_URL, `${API_GATEWAY_URL}/profile`),

        /** Public api key for Sign-in with Google */
        GOOGLE_SIGN_IN_CLIENT_ID: getStringFromEnv(process.env.GOOGLE_SIGN_IN_CLIENT_ID, undefined),

        /** URL for our PDF.js viewer */
        PDFJS_VIEWER_URL: getStringFromEnv(process.env.PDFJS_VIEWER, '/static/pdfjs/web/viewer.html'),

        /** src script for header bidding (ads) */
        YOURBOW_HEADER_BIDDING_URL: getStringFromEnv(process.env.YOURBOW_HEADER_BIDDING_URL, 'https://cdn.yourbow.com/ratehub/ratehub/hbyb_test.js'),

        /** where sendLog forwards log messages to */
        SEND_LOG_INGEST_URL: getStringFromEnv(process.env.SEND_LOG_INGEST_URL, '/api/log-ingest'),

        // URLs: Everyday Banking ---------------------------------------------------------------------

        /** Relative path to the National Bank Terms and Conditions English PDF */
        // ratehub.ca hard-wired to avoid CORS issues
        NBC_TERMS_AND_CONDITIONS_EN_PATH: getStringFromEnv(
            process.env.NBC_TERMS_AND_CONDITIONS_EN_PATH,
            'https://www.ratehub.ca/docs/National_Bank_Credit_Cards_TOC_en.pdf',
        ),

        /** Relative path to the National Bank Terms and Conditions French PDF */
        // ratehub.ca hard-wired to avoid CORS issues
        NBC_TERMS_AND_CONDITIONS_FR_PATH: getStringFromEnv(
            process.env.NBC_TERMS_AND_CONDITIONS_FR_PATH,
            'https://www.ratehub.ca/docs/National_Bank_Credit_Cards_TOC_fr.pdf',
        ),

        /** Base NBC URL at which several Payment Protection Plan documents are found */
        NBC_INSURANCE_DOCUMENTS_BASE_URL: getStringFromEnv(
            process.env.NBC_INSURANCE_DOCUMENTS_BASE_URL,
            'https://www.bnc.ca/content/dam/bnc/particuliers/pdf/assurance/',
        ),

        NBC_CUSTOMER_SERVICE_PHONE_TOLL_FREE: getStringFromEnv(
            process.env.NBC_CUSTOMER_SERVICE_PHONE_TOLL_FREE,
            '1\u2011888\u2011622\u20112783',   // using non-breaking hyphen U+2011
        ),

        NBC_CUSTOMER_SERVICE_PHONE: getStringFromEnv(
            process.env.NBC_CUSTOMER_SERVICE_PHONE,
            '514\u2011394\u20111427',   // using non-breaking hyphen U+2011
        ),

        NBC_EQUIFAX_PHONE: getStringFromEnv(
            process.env.NBC_EQUIFAX_PHONE,
            '1\u2011800\u2011465\u20117166',   // using non-breaking hyphen U+2011
        ),

        /** Home to wonderful APIs for /card, /card-mix, /card-list, /card-details and more */
        CC_API_URL: getStringFromEnv(process.env.CC_API_URL, `${API_GATEWAY_URL}/credit-cards-service`),

        /** CC INGESTOR API for getting and confirming update differences made on CMS */
        CC_INGESTOR_API_URL: getStringFromEnv(process.env.CC_INGESTOR_API_URL, `${API_GATEWAY_URL}/credit-cards-ingestor`),

        /** WordPress endpoint for cryptocurrency product types */
        CRYPTO_PRODUCT_API_URL: getStringFromEnv(process.env.CRYPTO_PRODUCT_API_URL, CONTENT_API_URL + '/wp/v2/crypto_currency'),

        /**  */
        CC_AGGREGATOR_API_URL: getStringFromEnv(
            process.env.CC_AGGREGATOR_API_URL,
            `${API_GATEWAY_URL}/credit-card-aggregator`,
        ),

        /** enable credit card’s secondary “check eligibility” button (disable if we can’t check user’s credit score) */
        ENABLE_CC_CHECK_ELIGIBILITY_CTA: getBooleanFromEnv(process.env.ENABLE_CC_CHECK_ELIGIBILITY_CTA, true),

        /** enable credit card’s CardFinder’s ability to query TransUnion’s for credit report information */
        ENABLE_CC_TRANSUNION: getBooleanFromEnv(process.env.ENABLE_CC_TRANSUNION, true),

        /** enable CardFinder’s use of approval likelihood scores */
        ENABLE_CC_APPROVAL_LIKELIHOOD: getBooleanFromEnv(process.env.ENABLE_CC_APPROVAL_LIKELIHOOD, true),

        /** Savings API used for product tables and queues  */
        SAVINGS_API_URL: getStringFromEnv(process.env.SAVINGS_API_URL, API_GATEWAY_URL + '/savings-accounts-service'),

        /** GICs API used for product tables and queues  */
        GICS_API_URL: getStringFromEnv(process.env.GICS_API_URL, API_GATEWAY_URL + '/gics-rates-service'),

        /** Loan API used for prequalified, tables, and queues  */
        LOAN_API_URL: getStringFromEnv(process.env.LOAN_API_URL, `${API_GATEWAY_URL}/loans-service`),

        /** Chequing API used for product tables and queues  */
        CHEQUING_API_URL: getStringFromEnv(process.env.CHEQUING_API_URL, API_GATEWAY_URL + '/chequing-accounts-service'),

        /** Robo API used for product queues  */
        ROBO_API_URL: getStringFromEnv(process.env.ROBO_API_URL, API_GATEWAY_URL + '/robo-advisors-service'),

        // URLs: Insurance ----------------------------------------------------------------------------

        /**
         * Base URL for insurance
         * https://www.ratehub.dev/insurance
         */
        INSURANCE_BASE_URL: getStringFromEnv(
            process.env.INSURANCE_BASE_URL,
            RATEHUB_BASE_URL + '/insurance',
        ),

        /**
         * Base API URL for insurance
         * https://api.ratehub.dev/insurance
         */
        INSURANCE_API_URL,

        /**
         * AUTO
         */
        INS_AUTO_LICENCE_ERROR_URL_ALBERTA: getStringFromEnv(
            process.env.INS_AUTO_LICENCE_ERROR_URL_ALBERTA,
            'https://RHInsuranceAppointmentBooking.as.me/alberta?field:11643859=AB-licence-issue',
        ),
        INS_AUTO_LICENCE_ERROR_URL_ONTARIO: getStringFromEnv(
            process.env.INS_AUTO_LICENCE_ERROR_URL_ONTARIO,
            'https://RHInsuranceAppointmentBooking.as.me/standard-broker-appointment?field:11643859=ON-licence-issue',
        ),

        /** QC Home fallback URL */
        INSURANCE_QC_HOME_FALLBACK_URL: getStringFromEnv(
            process.env.INSURANCE_QC_HOME_FALLBACK_URL,
            'https://www.app.youset.ca/',
        ),

        /** QC Condo fallback URL */
        INSURANCE_QC_CONDO_FALLBACK_URL: getStringFromEnv(
            process.env.INSURANCE_QC_CONDO_FALLBACK_URL,
            'https://www.app.youset.ca/',
        ),

        /** Canwise life insurance URL */
        CANWISE_LIFE_INSURANCE_URL: getStringFromEnv(
            process.env.CANWISE_LIFE_INSURANCE_URL,
            'https://www.canwise.com/life-insurance',
        ),

        /** PolicyMe base URL */
        POLICYME_BASE_URL: getStringFromEnv(
            process.env.POLICYME_BASE_URL,
            'https://www.policyme.com/life/life/life-insurance-quotes-continued',
        ),

        /** YouSet base URL
         * Product will be added to the end of the URL for the "p" param
        */
        YOUSET_BASE_URL: getStringFromEnv(
            process.env.YOUSET_BASE_URL,
            'https://www.app.youset.ca/source?&sourceId=sou_rat2015f66304a6569fab57f70ff4cfb&t=default&utm_medium=referral&utm_source=ratehub&p=',
        ),

        /** Life Insurance Wizard EN and FR base routes */
        INS_LIFE_WIZARD_BASE_ROUTE_EN: getStringFromEnv(
            process.env.INS_LIFE_WIZARD_BASE_ROUTE_EN,
            '/insurance/life-quotes',
        ),
        INS_LIFE_WIZARD_BASE_ROUTE_FR: getStringFromEnv(
            process.env.INS_LIFE_WIZARD_BASE_ROUTE_FR,
            '/assurance/soumission-de-la-vie',
        ),

        /**  Mitchell & Whale redirect URL for ins-moto users with licenceClass !== 'M' */
        MOTO_LICENCE_ERROR_REDIRECT_URL: getStringFromEnv(
            process.env.MOTO_LICENCE_ERROR_REDIRECT_URL,
            'https://mitchellwhale.com/motorcycle-insurance/m1-quote/',
        ),

        /**  */
        INSURANCE_QUOTE_START_URL: getStringFromEnv(
            process.env.INSURANCE_QUOTE_START_URL,
            API_GATEWAY_URL + '/quote-start',
        ),

        /**  */
        INSURANCE_HOME_CROSS_SELL_BOOKING_URL: getStringFromEnv(process.env.INSURANCE_HOME_CROSS_SELL_BOOKING_URL, null),

        /** Construction fetch failure booking URL */
        INS_FAILED_CONSTRUCTION_BOOKING_URL_AB: getStringFromEnv(
            process.env.INS_FAILED_CONSTRUCTION_BOOKING_URL_AB,
            'https://RHInsuranceAppointmentBooking.as.me/alberta?field:11643859=failed-quoter-AB',
        ),
        INS_FAILED_CONSTRUCTION_BOOKING_URL_BC: getStringFromEnv(
            process.env.INS_FAILED_CONSTRUCTION_BOOKING_URL_BC,
            'https://RHInsuranceAppointmentBooking.as.me/BC?field:11643859=failed-quoter-BC',
        ),
        INS_FAILED_CONSTRUCTION_BOOKING_URL_ON: getStringFromEnv(
            process.env.INS_FAILED_CONSTRUCTION_BOOKING_URL_ON,
            'https://RHInsuranceAppointmentBooking.as.me/standard-broker-appointment?field:11643859=failed-quoter-ON',
        ),

        /** Acuity calendar booking URL */
        // Still used in ins-moto until there is province support
        RH_INSURANCE_BASE_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_BASE_ACUITY_BOOKING_URL,
            'https://RHInsuranceAppointmentBooking.as.me/',
        ),

        /** Standard base Acuity calendar booking URL */
        // Note: There is a function createDynamicBookingURL that appends more information to the URL
        RH_INSURANCE_STANDARD_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_STANDARD_ACUITY_BOOKING_URL,
            'https://RHInsuranceAppointmentBooking.as.me/standard-broker-appointment?field:11643859=',
        ),

        /** Alberta base Acuity calendar booking URL */
        // Note: There is a function createDynamicBookingURL that appends more information to the URL
        RH_INSURANCE_ALBERTA_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_ALBERTA_ACUITY_BOOKING_URL,
            'https://RHInsuranceAppointmentBooking.as.me/alberta?field:11643859=',
        ),

        /** British Columbia base Acuity calendar booking URL */
        // Note: There is a function createDynamicBookingURL that appends more information to the URL
        RH_INSURANCE_BRITISH_COLUMBIA_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_BRITISH_COLUMBIA_ACUITY_BOOKING_URL,
            'https://RHInsuranceAppointmentBooking.as.me/BC?field:11643859=',
        ),

        /** ULF Alberta acuity link */
        RH_INSURANCE_ULF_ALBERTA_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_ULF_ALBERTA_ACUITY_BOOKING_URL,
            'https://rhinsuranceappointmentbooking.as.me/alberta?field:11643859=AB-ULF-confirmation',
        ),

        /** ULF British Columbia acuity link */
        RH_INSURANCE_ULF_BRITISH_COLUMBIA_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_ULF_BRITISH_COLUMBIA_ACUITY_BOOKING_URL,
            'https://rhinsuranceappointmentbooking.as.me/BC?field:11643859=BC-ULF-confirmation',
        ),

        /** ULF Ontario acuity link */
        RH_INSURANCE_ULF_ONTARIO_ACUITY_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_ULF_ONTARIO_ACUITY_BOOKING_URL,
            'https://rhinsuranceappointmentbooking.as.me/ULF-ON?field:11643859=ULF-ON',
        ),

        /** ULF Business base Acuity link */
        RH_INSURANCE_ULF_BUSINESS_BASE_BOOKING_URL: getStringFromEnv(
            process.env.RH_INSURANCE_ULF_BUSINESS_BASE_BOOKING_URL,
            'https://rhinsuranceappointmentbooking.as.me/business-insurance?field:11643859=',
        ),

        /** URL for scheduling a call when there are no quotes */
        RH_INSURANCE_NO_QUOTES_SCHEDULE_CALL: getStringFromEnv(
            process.env.RH_INSURANCE_NO_QUOTES_SCHEDULE_CALL,
            'https://rhinsuranceappointmentbooking.as.me/standard-broker-appointment?field:11643859=',
        ),

        /** URL for scheduling a call on moto confirmation page */
        RH_INSURANCE_MOTO_CONFIRMATION_SCHEDULE_CALL: getStringFromEnv(
            process.env.RH_INSURANCE_MOTO_CONFIRMATION_SCHEDULE_CALL,
            'https://RHInsuranceAppointmentBooking.as.me/moto-insurance?field:11643859=ON-moto-confirmation',
        ),

        /** URL for scheduling a call on Alberta moto confirmation page */
        RH_INSURANCE_MOTO_AB_CONFIRMATION_SCHEDULE_CALL: getStringFromEnv(
            process.env.RH_INSURANCE_MOTO_AB_CONFIRMATION_SCHEDULE_CALL,
            'https://rhinsuranceappointmentbooking.as.me/AB-moto-insurance?field:11643859=AB-moto-confirmation',
        ),

        /** 
         * Base URL for constructing a CIS booking URL
         * Used in pair with BASE_CANWISE_BOOKING_ERROR_PARAMS
        */
        BASE_CANWISE_BOOKING_URL: getStringFromEnv(
            process.env.BASE_CANWISE_BOOKING_URL,
            'https://canwiseinsuranceservices.as.me',
        ),
        /**
         * Base params for constructing a CIS booking URL
         * Used in pair with BASE_CANWISE_BOOKING_URL
        */
        BASE_CANWISE_BOOKING_ERROR_PARAMS: getStringFromEnv(
            process.env.BASE_CANWISE_BOOKING_ERROR_PARAMS,
            'field:13103377=quoter-error',
        ),

        INS_LIFE_ERROR_MODAL_BOOKING_URL: getStringFromEnv(
            process.env.INS_LIFE_ERROR_MODAL_BOOKING_URL,
            'https://canwiseinsuranceservices.as.me/?field:13103377=error-modal',
        ),

        /**  */
        INSURANCE_API_AUTO_GET_URL: getStringFromEnv(
            process.env.INSURANCE_API_AUTO_GET_URL,
            INSURANCE_API_URL + '/auto/get-quote',
        ),

        /**  */
        INSURANCE_API_AUTO_SUBMIT_URL: getStringFromEnv(
            process.env.INSURANCE_API_AUTO_SUBMIT_URL,
            INSURANCE_API_URL + '/auto/select-quote',
        ),

        /**  */
        INSURANCE_API_CONDO_GET_URL: getStringFromEnv(
            process.env.INSURANCE_API_CONDO_GET_URL,
            INSURANCE_API_URL + '/condo/get-quote',
        ),

        /**  */
        INSURANCE_API_HOME_GET_URL: getStringFromEnv(
            process.env.INSURANCE_API_HOME_GET_URL,
            INSURANCE_API_URL + '/home/get-quote',
        ),

        /**  */
        INSURANCE_API_HOME_PROPERTY_URL: getStringFromEnv(
            process.env.INSURANCE_API_HOME_PROPERTY_URL,
            INSURANCE_API_URL + '/home/get-property',
        ),

        /** URL for auto insurance landing page */
        AUTO_INSURANCE_HOME_PAGE_URL: getStringFromEnv(
            process.env.AUTO_INSURANCE_HOME_PAGE_URL,
            RATEHUB_BASE_URL + '/insurance/best-car-insurance-quote',
        ),

        /** URL for home insurance landing page */
        HOME_INSURANCE_HOME_PAGE_URL: getStringFromEnv(
            process.env.AUTO_INSURANCE_HOME_PAGE_URL,
            RATEHUB_BASE_URL + '/insurance/best-home-insurance-quote',
        ),

        /**  */
        VEHICLE_INFO_SERVICE_API_URL: getStringFromEnv(
            process.env.VEHICLE_INFO_SERVICE_API_URL,
            API_GATEWAY_URL + '/applied-vicc-lookup',
        ),

        /**  */
        INSURANCE_PROVIDER_ASSIGNMENT_API_URL: getStringFromEnv(
            process.env.INSURANCE_PROVIDER_ASSIGNMENT_API_URL,
            API_GATEWAY_URL + '/ins-broker-assignment',
        ),

        /**  */
        INSURANCE_PROVIDER_ASSIGNMENT_OVERRIDE: getStringFromEnv(process.env.INSURANCE_PROVIDER_ASSIGNMENT_OVERRIDE, null),

        /** Square one URL to load within an iframe, to provide a "widget-like" experience */
        SQUARE_ONE_IFRAME_URL: getStringFromEnv(
            process.env.SQUARE_ONE_IFRAME_URL,
            'https://www.squareone.ca/sales/affiliate/widget?code=RATEHUB&culture=en_CA',
        ),

        // URLs: Mortgages ----------------------------------------------------------------------------

        /** Full URL to the Ratehub mortgages API. */
        MORTGAGES_LEGACY_API_URL: getStringFromEnv(process.env.MORTGAGES_LEGACY_API_URL, API_LEGACY_URL + '/v1/mortgages'),

        /** Full URL to the Ratehub mortgage rates API. */
        MORTGAGE_RATES_API_URL: getStringFromEnv(process.env.MORTGAGE_RATES_API_URL, API_GATEWAY_URL + '/mortgage-rates'),

        /* Full URL of mortgage breakage penalty calcultor FaaS */
        MORTGAGE_PENALTY_BREAKAGE_API_URL: getStringFromEnv(
            process.env.MORTGAGE_PENALTY_BREAKAGE_API_URL,
            `${API_GATEWAY_URL}/mortgage-calculator/get_breakage_penalty`,
        ),

        /** Full URL to the Ratehub mortgages agent details */
        MORTGAGE_AGENT_DETAILS_API_URL: getStringFromEnv(process.env.MORTGAGE_AGENT_DETAILS_API_URL, API_GATEWAY_URL + '/agent/id'),

        /** Base URL to the Online Mortgage Platform */
        OMP_APP_URL: getStringFromEnv(process.env.OMP_APP_URL, 'https://kennedy.canwise.com'),

        /**  */
        OMP_API_URL: getStringFromEnv(process.env.OMP_API_URL, API_GATEWAY_URL + '/api/core'),

        /**  */
        CW_GATEWAY_URL,

        /**  */
        CW_OMP_API_URL: getStringFromEnv(process.env.CW_OMP_API_URL, CW_GATEWAY_URL + '/api/core'),

        // *******
        // Configs
        // *******

        // Configs: General (non-BU specific) ---------------------------------------------------------
        /** Site chrome variables */
        SITE_DEFAULT_PAGE_TITLE: getStringFromEnv(process.env.SITE_DEFAULT_PAGE_TITLE, 'Ratehub.ca'),

        /** Which brand's branding to use in the chrome */
        SITE_BRANDING: getStringFromEnv(process.env.SITE_BRANDING, 'ratehub'),

        /** Domain used when setting cookies; must be a superdomain of whatever domain users will access i.e. ratehub.ca instead of www.ratehub.ca */
        COOKIE_DOMAIN: getStringFromEnv(process.env.COOKIE_DOMAIN),

        /** An arry of ad units to lazy load. By default, ads are NOT lazy loaded so this is a whitelist of ad units. */
        LAZY_LOADED_ADS: getJsonFromEnv(process.env.LAZY_LOADED_ADS, []),

        /**  */
        NR_BROWSER_KEY: getStringFromEnv(process.env.NR_BROWSER_KEY, '01f43ed725'),

        /**  */
        NR_BROWSER_APP_ID: getStringFromEnv(process.env.NR_BROWSER_APP_ID, '899725491'),

        /** Represents the NR policy that our exporter is tied to.
         * This will help us filter in NR by policy and see which browser errors are tied to each policy. */
        NR_POLICY_NAME: getStringFromEnv(process.env.NR_POLICY_NAME),

        /**  */
        NR_BROWSER_ENABLE_DISTRIBUTED_TRACING: getBooleanFromEnv(process.env.NR_BROWSER_ENABLE_DISTRIBUTED_TRACING, true),

        /**  */
        NR_BROWSER_CORS_USE_HEADER: getBooleanFromEnv(process.env.NR_BROWSER_CORS_USE_HEADER, false),

        /**  */
        NR_BROWSER_CORS_TRACE_CONTEXT: getBooleanFromEnv(process.env.NR_BROWSER_CORS_TRACE_CONTEXT, true),

        /** Need to force the use of the external gateway URL here as it is included in the exported HTML */
        NR_BROWSER_ALLOWED_DESTINATIONS: getJsonFromEnv(process.env.NR_BROWSER_ALLOWED_DESTINATIONS, [ getStringFromEnv(process.env.API_GATEWAY_URL, 'https://api.ratehub.dev') ]),

        /** Public Google Analytics key for use in our <script> tag */
        GA_KEY: getStringFromEnv(process.env.GA_KEY, 'UA-17477445-4'),

        /** Public key for Google Analytics 4 */
        GA4_KEY: getStringFromEnv(process.env.GA_KEY, 'G-YYN3GRPCPN'),

        /** Public Heap to use in our Heap <script> tag */
        HEAP_KEY: getStringFromEnv(process.env.HEAP_KEY),

        /** Public Google Tag Manager key to use in our <script> tag */
        GTM_KEY: getStringFromEnv(process.env.GTM_KEY, 'GTM-MN8QRHD'),

        /** Query attached to the end of our GTM script include */
        GTM_QUERY: getStringFromEnv(
            process.env.GTM_QUERY,
            '&gtm_auth=5gj2-DF-4HjQcaMTvpkIBQ&gtm_preview=env-9&gtm_cookies_win=x',
        ),

        /** Key for generating Google Recaptcha */
        RECAPTCHA_SITE_KEY: getStringFromEnv(process.env.RECAPTCHA_SITE_KEY, '6LfqAyUaAAAAAMFVRfMsythORWXlSlMRkCjonUpm'),

        /** ID of a test post we use for QA, but which often want to filter it out of real page content */
        BLOG_TEST_POST_ID: getIntegerFromEnv(process.env.BLOG_TEST_POST_ID, 25181),

        /** ID of the generic post author from WP */
        BLOG_GENERIC_AUTHOR_ID_EN: getIntegerFromEnv(process.env.BLOG_GENERIC_AUTHOR_ID_EN, 2),
        BLOG_GENERIC_AUTHOR_ID_FR: getIntegerFromEnv(process.env.BLOG_GENERIC_AUTHOR_ID_FR, 2),

        /** ID of the generic siderail config from WP */
        BLOG_GENERIC_SIDERAIL_CONFIG_ID_EN: getIntegerFromEnv(process.env.BLOG_GENERIC_SIDERAIL_CONFIG_ID_EN, 164411),
        BLOG_GENERIC_SIDERAIL_CONFIG_ID_FR: getIntegerFromEnv(process.env.BLOG_GENERIC_SIDERAIL_CONFIG_ID_FR, 164414),

        /** ID for the 'blogEN' team taxonomy */
        BLOG_TEAM_ID_EN: getIntegerFromEnv(process.env.BLOG_TEAM_ID_EN, 1488),

        /** ID for the 'blogFR' team taxonomy */
        BLOG_TEAM_ID_FR: getIntegerFromEnv(process.env.BLOG_TEAM_ID_FR, 1490),

        /** Phone number for contacting Ratehub support */
        RATEHUB_SUPPORT_PHONE: getStringFromEnv(process.env.RATEHUB_SUPPORT_PHONE, '1-800-679-9622'),

        /** Phone number for contacting CanWise support */
        CANWISE_SUPPORT_PHONE: getStringFromEnv(process.env.CANWISE_SUPPORT_PHONE, '1-844-808-3379'),

        /** Profile schema version - should be set in the header of get-session call and etc */
        PROFILE_SCHEMA_VERSION: getIntegerFromEnv(process.env.PROFILE_SCHEMA_VERSION, 1),

        /** Special PLACEHOLDER to be modified by a Cloudflare worker. Indicates any <ExperimentSegment> which the user isn't in have been removed */
        CLOUDFLARE_HAS_PRUNED_EXPERIMENTS: false,

        /** Enable console.info for notice error save events */
        ENABLE_NOTICE_ERROR_SAVE_EVENT: getBooleanFromEnv(process.env.ENABLE_NOTICE_ERROR_SAVE_EVENT, false),

        /** Enable header 'x-ratehub-enable-external-cookie' to be controlled in fetchJSON */
        ENABLE_EXTERNAL_COOKIE: getBooleanFromEnv(process.env.ENABLE_EXTERNAL_COOKIE, false),

        /** Timeout config for <CircleSpinner />s */
        CIRCLE_SPINNER_TIMEOUT_MILLISECONDS: getIntegerFromEnv(process.env.CIRCLE_SPINNER_TIMEOUT_MILLISECONDS, 40_000),

        // Configs: Everyday Banking ------------------------------------------------------------------

        /** Disable CardFinder route rewrite in server.js */
        DISABLE_CARDFINDER_REWRITE: getBooleanFromEnv(process.env.DISABLE_CARDFINDER_REWRITE, false),

        /** Timeout config for National Bank and their versatile backend */
        NATIONAL_BANK_TIMEOUT_MILLISECONDS: getIntegerFromEnv(process.env.NATIONAL_BANK_TIMEOUT_MILLISECONDS, 90000),

        /** Providers that have CC CTA modal gate */
        CC_MODAL_GATE_PROVIDERS: getJsonFromEnv(
            process.env.CC_MODAL_GATE_PROVIDERS,
            [], // No providers by default
        ),

        // Configs: Insurance -------------------------------------------------------------------------

        /** The slug used by the API for the home quote start configs  */
        INSURANCE_QUOTE_START_HOME_SLUG: getStringFromEnv(process.env.INSURANCE_QUOTE_START_HOME_SLUG, 'home-quote-launcher-en'),

        /** The slug used by the API for the tenant quote start configs */
        INSURANCE_QUOTE_START_TENANT_SLUG: getStringFromEnv(process.env.INSURANCE_QUOTE_START_TENANT_SLUG, 'tenant-quote-launcher-en'),

        /** The slug used by the API for the condo quote start configs */
        INSURANCE_QUOTE_START_CONDO_SLUG: getStringFromEnv(process.env.INSURANCE_QUOTE_START_CONDO_SLUG, 'condo-quote-launcher-en'),

        /** The slug used by the API for the home quote start configs - on the french pages */
        INSURANCE_QUOTE_START_HOME_SLUG_FR: getStringFromEnv(process.env.INSURANCE_QUOTE_START_HOME_SLUG_FR, 'home-quote-launcher-fr'),

        /** The slug used by the API for the tenant quote start configs - on the french pages */
        INSURANCE_QUOTE_START_TENANT_SLUG_FR: getStringFromEnv(process.env.INSURANCE_QUOTE_START_TENANT_SLUG_FR, 'tenant-quote-launcher-fr'),

        /** The slug used by the API for the condo quote start configs - on the french pages */
        INSURANCE_QUOTE_START_CONDO_SLUG_FR: getStringFromEnv(process.env.INSURANCE_QUOTE_START_CONDO_SLUG_FR, 'condo-quote-launcher-fr'),

        /**  */
        INSURANCE_API_FORWARD_COOKIES: getBooleanFromEnv(process.env.INSURANCE_API_FORWARD_COOKIES, false),

        // Configs: Mortgages -------------------------------------------------------------------------

        /**  */
        OMP_PUSHER_API_KEY: getStringFromEnv(process.env.OMP_PUSHER_API_KEY, '10d876d0189879a2b1d7'),

        /**  */
        OMP_PUSHER_CLUSTER: getStringFromEnv(process.env.OMP_PUSHER_CLUSTER, 'us2'),

        /**  */
        MORTGAGES_ACCUITY_OWNER_ID: getStringFromEnv(process.env.ACCUITY_OWNER_ID, '19831391'),

        MORTGAGES_ACUITY_CONFIRMATION_PARAMS: getJsonFromEnv(process.env.MORTGAGES_ACUITY_CONFIRMATION_PARAMS, {
            fields: [ {
                id: 10091186,
                value: 'APP.ca-Native-Acuity',
            } ],
        }),

        /** Mortgage Agent Pages Siderail Setting IDs (from WP) */
        MORTGAGE_AGENT_PAGES_SIDERAIL_SETTING_ID_EN: getStringFromEnv(
            process.env.MORTGAGE_AGENT_PAGES_SIDERAIL_SETTING_ID_EN,
            148681,
        ),
        MORTGAGE_AGENT_PAGES_SIDERAIL_SETTING_ID_FR: getStringFromEnv(
            process.env.MORTGAGE_AGENT_PAGES_SIDERAIL_SETTING_ID_FR,
            148681,
        ),

        // *************
        // Feature Flags
        // *************

        // Feature Flags: General (non-BU specific) ---------------------------------------------------

        /** If we should use the symbol-based replacement for react-intl language. */
        PSEUDOLOC_ENABLED: getBooleanFromEnv(
            process.env.PSEUDOLOC_ENABLED,
            typeof process.env.NODE_ENV === 'undefined' || process.env.NODE_ENV !== 'production',
        ),

        /** If all external service fetches should be cached while doing our server-side generation */
        ENABLE_SERVER_FETCH_CACHING: getBooleanFromEnv(process.env.ENABLE_SERVER_FETCH_CACHING, true),

        /** If calls for ads should actually make calls to Google Ad Manager */
        ENABLE_ADS: getBooleanFromEnv(process.env.ENABLE_ADS, true),

        /** Places requests to many ad networks instead of just ones configured in Google Ad Manager. Requires ENABLE_ADS=true  */
        ENABLE_AD_HEADER_BIDDING: getBooleanFromEnv(process.env.ENABLE_AD_HEADER_BIDDING, false),

        /** Enables a cookie banner/modal to be displayed when 1st party cookies are not available */
        ENABLE_COOKIE_PROMPT: getBooleanFromEnv(process.env.ENABLE_COOKIE_PROMPT, true),

        /** Enables using a POST request to submit document events rather than mutating documents to add events/inquiries  */
        ENABLE_DOCUMENT_EVENTS_API: getBooleanFromEnv(process.env.ENABLE_DOCUMENT_EVENTS_API, false),

        /** Places requests for special test ad units - useful for viewing & testing ads in development */
        ENABLE_TEST_ADS: getBooleanFromEnv(process.env.ENABLE_TEST_ADS, false),

        /** If DISABLED, forces all experiments to use CONTROL (0) */
        ENABLE_EXPERIMENTS: getBooleanFromEnv(process.env.ENABLE_EXPERIMENTS, true),

        /* If we should add a trailing slash to exported page urls */
        ENABLE_URL_TRAILING_SLASH: getBooleanFromEnv(process.env.ENABLE_URL_TRAILING_SLASH, false),

        /** Allow server-only components to opt-out of the React hydration phase */
        ENABLE_HYDRATION_TOGGLE: getBooleanFromEnv(process.env.ENABLE_HYDRATION_TOGGLE, false),

        /** Feature flag to toggle whether Save to Profile component will be displayed */
        ENABLE_SAVE_TO_PROFILE_BUTTON: getBooleanFromEnv(process.env.ENABLE_SAVE_TO_PROFILE_BUTTON, false),

        /** Feature flag to toggle Save result button specifically for mortgage payment calculator */
        ENABLE_SAVE_TO_PROFILE_BUTTON_PAYMENT_CALC: getBooleanFromEnv(process.env.ENABLE_SAVE_TO_PROFILE_BUTTON_PAYMENT_CALC, false),

        /** Feature flag to toggle whether to use MagicLinkForms on Save to Profile component*/
        ENABLE_SIGN_IN_ON_SAVE_TO_PROFILE_BUTTON: getBooleanFromEnv(process.env.ENABLE_SIGN_IN_ON_SAVE_TO_PROFILE_BUTTON, false),

        /** */
        ENABLE_GOOGLE_SSO_ON_HEADER_MENU: getBooleanFromEnv(process.env.ENABLE_GOOGLE_SSO_ON_HEADER_MENU, false),

        /** If changes to the profile document are allowed to use PATCH. If false, all changes will result in a POST. */
        ENABLE_DOCUMENT_PATCHING: getBooleanFromEnv(process.env.ENABLE_DOCUMENT_PATCHING, false),

        /** Turn on/off the use of SessionStoreProvider at web-server's _app */
        ENABLE_SESSION_STORE_PROVIDER: getBooleanFromEnv(process.env.ENABLE_SESSION_STORE_PROVIDER, true),

        /**  */
        ENABLE_WIDGET_LOADER: getBooleanFromEnv(process.env.ENABLE_WIDGET_LOADER, false),

        /* Allow showing ads in the main page sidebar on WordPress pages */
        ENABLE_SIDEBAR_ADS: getBooleanFromEnv(process.env.ENABLE_SIDEBAR_ADS, true),

        /** If any calls to fetchJSON will result in a log entry. Helful for debugging. */
        ENABLE_FETCH_JSON_LOGGING: getBooleanFromEnv(process.env.ENABLE_FETCH_JSON_LOGGING, false),

        /** Enables sticky mobile ad when all other conditions allow it */
        ENABLE_STICKY_MOBILE_AD: getBooleanFromEnv(process.env.ENABLE_STICKY_MOBILE_AD, false),

        // Feature Flags: Everyday Banking ------------------------------------------------------------

        /** Have we enabled Additional Users for NBC? */
        ENABLED_CC_NBC_ADDITIONAL_USERS: getBooleanFromEnv(process.env.ENABLED_CC_NBC_ADDITIONAL_USERS, false),

        /** Flag to enable FE support for the First Home Savings Account */
        ENABLE_FHSA: getBooleanFromEnv(process.env.ENABLE_FHSA, false),

        /** Flag to enable FE using custom apply slug instead of goto_64 */
        ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION: getBooleanFromEnv(process.env.ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION, false),

        /** Flag to enable FE CC Rate Table filters */
        ENABLE_CC_RATE_TABLE_FILTERS: getBooleanFromEnv(process.env.ENABLE_CC_RATE_TABLE_FILTERS, false),

        // Feature Flags: Insurance -------------------------------------------------------------------
        
        // For urgent messages if we need to get them to prod immediately
        INS_WARNING_BANNER_MESSAGE: getStringFromEnv(process.env.INS_WARNING_BANNER_MESSAGE),

        // Auto ---------------------------
        /**
         * Feature flag to enable QC in ins-auto
         * Cleanup in INS-3735
         */
        ENABLE_INS_AUTO_QC: getBooleanFromEnv(process.env.ENABLE_INS_AUTO_QC, false),

        // Condo --------------------------
        /**
         * Feature flag to enable QC in ins-auto
         * Cleanup in INS-3736
         */
        ENABLE_INS_CONDO_QC: getBooleanFromEnv(process.env.ENABLE_INS_CONDO_QC, false),

        // Home ---------------------------
        /**
         * Feature flag to enable QC in ins-auto
         * Cleanup in INS-3737
         */
        ENABLE_INS_HOME_QC: getBooleanFromEnv(process.env.ENABLE_INS_HOME_QC, false),

        // Life ---------------------------
        /**
         * Cleanup in INS-3191
         * Flag to disable bundle quotes question
         */
        ENABLE_INS_LIFE_BUNDLE_QUOTE_QUESTION: getBooleanFromEnv(process.env.ENABLE_INS_LIFE_BUNDLE_QUOTE_QUESTION, false),

        /**
         * Cleanup in INS-3935
         * Flag to hide extra PolicyMe questions until ready
         */
        ENABLE_INS_LIFE_POLICY_ME_QUESTIONS: getBooleanFromEnv(process.env.ENABLE_INS_LIFE_POLICY_ME_QUESTIONS, false),

        /**
         * Cleanup in
         * Flag to enable life quoting
         */
        ENABLE_INS_LIFE_QUOTING: getBooleanFromEnv(process.env.ENABLE_INS_LIFE_QUOTING, false),

        // Feature Flags: Mortgages -------------------------------------------------------------------

        /** Enable flag to persist changes made to document for calculators */
        ENABLE_SAVING_DATA_FOR_CALCULATORS: getBooleanFromEnv(process.env.ENABLE_SAVING_DATA_FOR_CALCULATORS, false),

        /**  */
        ENABLE_MORTGAGES_TRANSACTION_TYPE_SWITCHING: getBooleanFromEnv(
            process.env.ENABLE_MORTGAGES_TRANSACTION_TYPE_SWITCHING,
            false,
        ),

        /**  */
        ENABLE_APPCA_SCENARIO_ROUTING: getBooleanFromEnv(process.env.ENABLE_APPCA_SCENARIO_ROUTING, false),

        /**  */
        ENABLE_GOC_MORTGAGE_REFORM: getBooleanFromEnv(process.env.ENABLE_GOC_MORTGAGE_REFORM, false),

        // *********
        // Experiments
        // *********

        /** Google Sign-in button on MagicLinkSignIn screen */
        SEGMENT_ENABLE_GOOGLE_SIGN_IN: getIntegerFromEnv(
            process.env.SEGMENT_GOOGLE_SIGN_IN,
            null,
        ),
        VARIATIONS_ENABLE_GOOGLE_SIGN_IN: getJsonFromEnv(
            process.env.VARIATIONS_ENABLE_GOOGLE_SIGN_IN,
            [ 0 ], // Only Control by default
        ),

        // -----
        // NOTE TO MAINTAINERS: Please add new keys to the proper section above (not just at the bottom of this file)
        //      Also please include a very brief explanation of what the key is for.
        // -----
    };
}

function getBooleanFromEnv(value, optionalDefault = undefined) {
    if ([ 'no', 'false', false, '0', 0 ].includes(value)) {
        return false;
    }

    return typeof value !== 'undefined' ? Boolean(value) : optionalDefault;
}

function getStringFromEnv(value, optionalDefault = undefined) {
    return typeof value !== 'undefined' ? String(value) : optionalDefault;
}

function getIntegerFromEnv(value, optionalDefault = undefined) {
    if (typeof value === 'undefined') {
        return optionalDefault;
    }

    const parsed = parseInt(value);

    if (isNaN(parsed)) {
        throw new RangeError(`environment.js - attempted to parse an integer from ${value} but resulted in NaN`);
    }

    return parsed;
}

function getJsonFromEnv(value, optionalDefault = undefined) {
    return typeof value !== 'undefined' ? JSON.parse(value) : optionalDefault;
}

module.exports = getEnvironment;
