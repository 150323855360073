import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TabContainer } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';
import TabbedContentItem from './TabbedContentItem';
import RichTextBlock from './RichTextBlock';


function TabbedContentContainer({
    heading,
    tabs = [],
    initialTabIndex = 0,
    forceRenderTabs = true,
    ...otherProps
}) {
    if (!tabs.length) {
        return null;
    }

    return (
        <Container {...otherProps}>
            <HeadingBlock
                variant="dark"
                {...heading}
            />

            <TabContainer
                orientation="vertical"
                initialTabIndex={initialTabIndex}
                forceRenderTabs={forceRenderTabs}
            >
                <For
                    each="tab"
                    of={tabs}
                    index="index"
                >
                    <TabbedContentItem
                        key={`${tab.title}-${index}`}
                        title={tab.title}
                    >
                        <RichTextBlock
                            content={tab.body}
                            variant="dark"
                            hasVerticalMargins={false}
                        />
                    </TabbedContentItem>
                </For>
            </TabContainer>
        </Container>
    );
}

TabbedContentContainer.propTypes = {
    heading: HeadingPropTypes,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
        }),
    ),
    initialTabIndex: PropTypes.number,
    forceRenderTabs: PropTypes.bool,
    children: PropTypes.any,
};

const Container = styled.div``;

TabbedContentContainer.blockKey = 'rh/tabbed-content-container';
TabbedContentContainer.requiresLayoutRow = true;
TabbedContentContainer.hasHeading = true;

export default TabbedContentContainer;
